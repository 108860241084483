import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import {
  ADD_PREFIX,
  GROUP,
  GROUPS,
  RESERVES,
  SESSION_LOG,
} from 'shared/consts/routes/common';
import {
  BANK_CARD_SESSIONS,
  BINDINGS,
  PERSON,
  PERSONS,
  PROFILE,
  TRANSACTIONS,
} from 'shared/consts/routes/person';

import { RequireAuth } from 'entities/authorization';
import { ConnectedPersonGroupsMenu } from 'entities/person';
import { PersonLayout } from 'entities/person/person-layout';
import { PersonsGroupLayout } from 'entities/person/persons-group-layout';
import { PersonsLayout } from 'entities/person/persons-layout';

import { EditPerson } from '.';

import {
  AddPersonPage,
  PersonBankCardSessionsPage,
  PersonBindingsPage,
  PersonGroupsPage,
  PersonListPage,
  PersonReservesPage,
  PersonSessionLogPage,
  PersonsGroupListPage,
  PersonsGroupReservesPage,
  PersonsGroupSessionLogPage,
  PersonsReservesPage,
  PersonsSessionLogPage,
  PersonTransactionLogPage,
} from 'pages/person';
import { AddPersonsGroup } from 'features/persons-group/add';
import { EditPersonsGroupPage } from 'pages/group';

export const PersonRoutes = (
  <Route element={<RequireAuth controllerName="Person" rightType="Read" />}>
    <Route
      path={PERSONS}
      element={<SectionLayout menu={<ConnectedPersonGroupsMenu />} />}
    >
      <Route element={<PersonsLayout />}>
        <Route index element={<PersonListPage />} />
        <Route
          element={<RequireAuth controllerName="Group" rightType="Read" />}
        >
          <Route path={GROUPS} element={<PersonGroupsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSION_LOG} element={<PersonsSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<PersonsReservesPage />} />
        </Route>
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Read" />}>
        <Route path={`${GROUPS}/${GROUP}`} element={<EditPersonsGroupPage />} />
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Read" />}>
        <Route path={GROUP} element={<PersonsGroupLayout />}>
          <Route index element={<PersonsGroupListPage />} />
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={SESSION_LOG}
              element={<PersonsGroupSessionLogPage />}
            />
          </Route>
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path="reserves" element={<PersonsGroupReservesPage />} />
          </Route>
        </Route>
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route
          path={`${GROUPS}/${ADD_PREFIX}`}
          element={<MainContentLayout element={<AddPersonsGroup />} />}
        />
      </Route>

      <Route
        element={<RequireAuth controllerName="Person" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddPersonPage />} />
      </Route>

      <Route path={`${GROUP}/${PERSON}`} element={<PersonLayout />}>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route index element={<PersonSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<PersonReservesPage />} />
        </Route>
        <Route path={BINDINGS} element={<PersonBindingsPage />} />
        <Route
          path={BANK_CARD_SESSIONS}
          element={<PersonBankCardSessionsPage />}
        />
        <Route path={PROFILE} element={<EditPerson />} />
        <Route path={TRANSACTIONS} element={<PersonTransactionLogPage />} />
      </Route>
    </Route>
  </Route>
);
