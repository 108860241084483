import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { hasNoLetters } from 'shared/lib';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

import { useLazyGetPersonsQuery } from 'entities/person';
import { selectTokenName } from 'entities/authorization';

import { Header } from '../header';
import { TABLE_PAGE_SIZE } from '../../../../constants/variables';
import { useGetUserQuery } from 'entities/user';

export function ConnectedHeader() {
  const [term, setTerm] = useState('');
  const [searchByChargePointName, setSearchByChargePointName] = useState(false);

  const userName = useSelector(selectTokenName);

  const navigate = useNavigate();

  const { data, isFetching, error } = useGetUserQuery(userName);

  const [trigger, { isLoading }] = useLazyGetPersonsQuery();

  const onSearch = async () => {
    if (hasNoLetters(term) && searchByChargePointName) {
      return navigate(
        `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?cpName=${term}`
      );
    }

    if (hasNoLetters(term) && !searchByChargePointName) {
      const res = await trigger({
        limit: TABLE_PAGE_SIZE, // TODO
        phone: term.replaceAll(/[\s\(\)-]/g, ''),
        page: 0,
      });

      if (res.data?.count === 1) {
        const { idGroup, login } = res.data.result[0];

        return navigate(
          `${PERSON_ROUTES.PERSONS_LIST}/${
            idGroup !== 0 ? idGroup : null
          }/${login}`
        );
      }

      if (res.data) {
        return navigate(`${PERSON_ROUTES.PERSONS_LIST}?phone=${term}`);
      }
    }

    return navigate(
      `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?search=operation.cpAddress&searchText=${term}`
    );
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setSearchByChargePointName(e.target.checked);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Отлов события очищения инпут-поля по крестику
    if (e.type !== 'change') {
      setTerm(e.target.value);
    }

    setTerm(e.target.value);
  };

  const firstName = data?.user.firstName ?? '';
  const lastName = data?.user.lastName ?? '';

  return (
    <Header
      onCheckboxChange={onCheckboxChange}
      onInputChange={onInputChange}
      onSearch={onSearch}
      searchByChargePointName={searchByChargePointName}
      term={term}
      loading={isLoading}
      firstName={firstName}
      lastName={lastName}
    />
  );
}
