import styled from 'styled-components';

const StyledVersionBadge = styled.div`
  background: #ffffff;
  border-width: 0.5px 0px 0px 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 0px 10px;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.35);

  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;

  display: flex;
  padding: 4px 10px;
`;

export function VersionBadge() {
  return <StyledVersionBadge>2024-05-v1</StyledVersionBadge>;
}
