import { Menu, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';
import { getOpenKeys } from 'shared/ui/ui-menu/lib/get-open-keys-array';
import {
  CHARGE_POINTS,
  CHARGE_POINT_ROUTES,
} from 'shared/consts/routes/charge-point';

import { GroupDto } from 'entities/group';
import { ChargePointDTO } from 'entities/charge-point';

import { StyledBasicMenu } from '../../../../../common-styles/basic-menu';

import { ChargePointMenuItem } from './charge-point-menu-item';
import {
  AddChargePointButton,
  AddChargePointGroupButton,
} from '../connected-menu/add-button';

const key = CHARGE_POINTS;

const keyPath = CHARGE_POINT_ROUTES.CHARGE_POINTS;
const label = 'ЭЗС';

type Props = {
  chargePoints: ChargePointDTO[];
  groups: GroupDto[];
  isChargePointsFetching: boolean;
  isGroupsLoading: boolean;
  hasChargePointCurrentData: boolean;
};

export function ChargePointsMenu({
  chargePoints,
  groups,
  isChargePointsFetching,
  isGroupsLoading,
  hasChargePointCurrentData,
}: Props) {
  const { groupId, chargePointName } = useParams() as {
    groupId: string;
    chargePointName: string;
  };

  return (
    <>
      <AddChargePointButton />
      <AddChargePointGroupButton />
      <StyledBasicMenu
        openKeys={
          groupId &&
          groupId !== 'null' &&
          (!isChargePointsFetching || hasChargePointCurrentData)
            ? ['root', ...getOpenKeys(groups, Number(groupId))]
            : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={isGroupsLoading}>
          <CustomLink
            to={keyPath}
            checkIsActive={(path) =>
              path.includes(`${key}?`) || path === keyPath
            }
          >
            <Menu.SubMenu key="root" title={label} eventKey="root">
              {groups
                .filter((k) => !k.parentId)
                .map((el) => buildMenuItems(el, groups))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );

  function buildMenuItems(group: GroupDto, groups: GroupDto[]) {
    const { id, name, childrenGroups } = group;

    const innerMenuContent =
      childrenGroups.length === 0 ? (
        chargePoints.map((el) => (
          <ChargePointMenuItem groupId={id} chargePoint={el} key={el.id} />
        ))
      ) : (
        <>
          {group.childrenGroups.map((childrenGroup) =>
            buildMenuItems(childrenGroup, groups)
          )}
          {chargePoints.map((el) => (
            <ChargePointMenuItem groupId={id} chargePoint={el} key={el.id} />
          ))}
        </>
      );

    const path = String(id) === groupId ? keyPath : String(id);

    return (
      <CustomLink to={path} key={path}>
        <Spin
          spinning={
            String(id) === groupId &&
            isChargePointsFetching &&
            !hasChargePointCurrentData
          }
        >
          <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
            {innerMenuContent}
          </Menu.SubMenu>
        </Spin>
      </CustomLink>
    );
  }
}
