import { ADD_PREFIX, GROUP, GROUPS, RESERVES, SESSION_LOG } from './common';

export const PERSONS = 'persons';
export const PERSON = ':userLogin';
export const BINDINGS = 'bindings';
export const BANK_CARD_SESSIONS = 'bank-card-sessions';
export const PROFILE = 'profile';
export const TRANSACTIONS = 'transactions';

export const PERSON_ROUTES = {
  PERSONS_LIST: `/${PERSONS}`,
  PERSONS_GROUPS_LIST: `/${PERSONS}/${GROUPS}`,
  PERSONS_SESSION_LOG: `/${PERSONS}/${SESSION_LOG}`,
  PERSONS_RESERVES: `/${PERSONS}/${RESERVES}`,
  // Группа клиентов
  PERSONS_GROUP_PERSON_LIST: `/${PERSONS}/${GROUP}`,
  PERSONS_GROUP_SESSION_LOG: `/${PERSONS}/${GROUP}/${SESSION_LOG}`,
  PERSONS_GROUP_RESERVES: `/${PERSONS}/${GROUP}/${RESERVES}`,
  // Клиент
  PERSON_SESSION_LOG: `/${PERSONS}/${GROUP}/${PERSON}`,
  PERSON_RESERVES: `/${PERSONS}/${GROUP}/${RESERVES}`,
  PERSON_BINDINGS: `/${PERSONS}/${GROUP}/${BINDINGS}`,
  PERSON_BANK_CARD_SESSIONS: `/${PERSONS}/${GROUP}/${BANK_CARD_SESSIONS}`,
  PERSON_PROFILE: `/${PERSONS}/${GROUP}/${PROFILE}`,
  PERSON_TRANSACTIONS: `/${PERSONS}/${GROUP}/${TRANSACTIONS}`,
  // Созданиен клиента
  ADD_PERSON: `/${PERSONS}/${ADD_PREFIX}`,
  // Создание/обновление группы клиентов
  PERSONS_GROUP: `/${PERSONS}/${GROUPS}/${GROUP}`,
  ADD_PERSONS_GROUP: `/${PERSONS}/${GROUPS}/${ADD_PREFIX}`,
};
