import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { DeleteGroupModal } from '../../delete/ui';
import { GroupDto } from 'entities/group';

type Props = {
  group: GroupDto;
};

export function GroupTableActions({ group }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight('Group', 'Write', roles, claims);

  const navigate = useNavigate();

  const showDeleteModal = () => {
    NiceModal.show(DeleteGroupModal, { group });
  };

  const content = (
    <>
      <button
        onClick={() => {
          navigate(String(group.id));
        }}
      >
        Редактировать
      </button>
      {hasRight && <button onClick={showDeleteModal}>Удалить</button>}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
